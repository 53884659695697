<template>
  <div class="message_content">
    <page-content>
      <div class="select_content">
        <SearchCard
          :list="statusArr"
          :select="status"
          place="header"
          @change="changeStatus"
        />
      </div>
      <div class="right-btn">
        <el-button
          type="primary"
          size="mini"
          @click="toRead"
        >
          标记为已读
        </el-button>
      </div>

      <!-- 列表 -->
      <el-table
        v-loading="loading"
        border
        :header-cell-style="{'background': '#F0F2F8', 'color': '#333'}"
        :data="tableData"
        element-loading-text="拼命加载中"
        style="width:100%;"
        :row-key="getRowKey"
        @selection-change="selectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
        />
        <el-table-column
          prop="content"
          label="消息内容"
        />
        <el-table-column
          prop="isRead"
          label="状态"
        >
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.isRead === 0"
              type="danger"
            >
              未读
            </el-tag>
            <el-tag
              v-if="scope.row.isRead === 1"
              type="success"
            >
              已读
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="时间"
        >
          <template slot-scope="scope">
            {{ dayFormat(scope.row.createTime) }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="pagination.currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pagination.nowPageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.count"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </page-content>
  </div>
</template>

<script>
import SearchCard from '@/components/common/SearchCard'
import dayjs from 'dayjs'

export default {
  name: 'MessageList',
  components: {
    SearchCard
  },
  data() {
    return {
      status: '2', // 消息状态
      statusArr: [{ value: '2', label: '全部' }, { value: '0', label: '未读' }, { value: '1', label: '已读' }],
      ids: [],
      loading: true,
      tableData: [],
      pagination: {
        currentPage: 1,
        nowPageSize: 10,
        count: 0
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getRowKey(row) {
      return row.id
    },
    dayFormat(val) {
      return dayjs(parseInt(val)).format('YYYY/MM/DD HH:mm:ss')
    },
    handleSizeChange(val) {
      this.pagination.nowPageSize = val
      this.pagination.currentPage = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val
      this.getList()
    },
    changeStatus(item) {
      this.status = item.value
      this.getList()
    },
    // 列表数据
    getList(index) {
      this.loading = true
      this.$axios.get(this.$api.message.list, {
        params: {
          currentPage: this.pagination.currentPage,
          pageSize: this.pagination.nowPageSize,
          isRead: this.status
        }
      }).then((res) => {
        let r = res
        if (r.code === 0) {
          const { size, total, current, pages } = r.data
          this.pagination = {
            currentPage: Number(current),
            nowPageSize: Number(size),
            count: Number(total)
          }
          this.tableData = r.data.records
        } else {
          this.tableData = []
        }
        this.loading = false
      })
    },
    // 表格选中
    selectionChange(val) {
      this.ids = []
      val.forEach(element => {
        this.ids.push(element.id)
      })
    },
    // 标记为已读
    toRead() {
      this.$axios(this.$api.message.setRead, {
        params: {
          ids: this.ids.join(',')
        }
      }).then(res => {
        let r = res
        if (r.code === 0) {
          this.$message.success('操作成功')
          this.getList()
          // window.location.reload()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .message_content {
    position: relative;

  }
  .right-btn {
    position: absolute;
    top: 24px;
    right: 40px;
    z-index: 2;
  }
</style>
